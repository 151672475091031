<template>
  <b-modal
    :visible="show"
    id="modal-question-detail"
    centered
    :title="!isDetail ? 'Chỉnh sửa câu hỏi' : 'Chi tiết câu hỏi '"
    size="lg"
    @hide="resetModal"
  >
    <b-overlay :show="loading">
      <div class="question">
        <div class="question__name">
          {{ question.body }}
        </div>
        <div
          class="question__status"
          :class="getClassByStatus(question.statusPortal)"
        >
          {{ getStatus(question.statusPortal) }}
        </div>
      </div>
      <div class="d-flex align-items-center my-4">
        <avatar
          size="40px"
          :text="question.creator"
          :rounded="true"
          :src="question.creatorUrl && question.creatorUrl.url"
        />
        <div class="d-flex ml-5 justify-content-center flex-column">
          <div class="font-weight-bolder">{{ question.creator }}</div>
          <div class="text-gray">{{ formatDate(question.createDateTime) }}</div>
        </div>
      </div>
      <div class="row" v-if="question.pictureUrls">
        <div class="col-lg-12">
          <div class="d-flex">
            <div
              class="preview"
              v-for="(image, index) in question.pictureUrls"
              :key="index"
              v-if="index <= 2"
            >
              <img
                style="width: 100px; height: 100px"
                :src="image.url"
                class="preview-image mr-3"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="answer my-8">
        <div
          v-if="question.answers && question.answers.length"
          class="question__name"
        >
          Trả lời:
        </div>
        <template v-for="answer in question.answers">
          <div :key="answer.id" class="mb-4">
            <div class="position-relative">
              <div class="d-flex align-items-center my-4">
                <avatar
                  size="40px"
                  :text="answer.account.fullName"
                  :rounded="true"
                  :src="
                    answer.account.avatar ? answer.account.avatar.url : null
                  "
                />
                <div class="d-flex ml-5 justify-content-center flex-column">
                  <div class="font-weight-bolder">
                    {{ answer.account.fullName }}
                  </div>
                  <div class="text-gray">
                    {{ formatDate(answer.createDateTime) }}
                  </div>
                </div>
              </div>
              <b-dropdown
                v-if="!isDetail"
                id="dropdown-right dropdown-form"
                class="actions custom-v-dropdown"
                no-caret
                right
                variant="link"
              >
                <template #button-content>
                  <span class="menu-icon svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/more-vertical.svg"
                    />
                  </span>
                </template>
                <div class="navi navi-hover min-w-md-132px">
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="showPopupDeleteAnswer(answer)"
                  >
                    <a class="navi-link text-danger">
                      <b-icon-trash class="mr-2" scale="1.2" />
                      <span class="navi-text text-danger">Xóa câu trả lời</span>
                    </a>
                  </b-dropdown-text>
                </div>
              </b-dropdown>
            </div>
            <div v-html="answer.body"></div>
          </div>
        </template>
      </div>
      <form v-if="!isDetail" @submit.prevent="handleValidate">
        <b-row>
          <b-col cols="4">
            <basic-select
              placeholder="--- Chọn chuyên gia---"
              label="Chuyên gia"
              :options="professors"
              :value.sync="form.professor"
              required
              name="professor"
              :state="validateState(`professor`)"
              :invalid-feedback="errors.first(`professor`)"
              data-vv-as="Chuyên gia"
              v-validate="{
                required: true,
              }"
              value-label="fullName"
              changeValueByObject
              @update:value="$validator.reset({ name: 'professor' })"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-text-editors
              name="body"
              placeholder="Nhập câu trả lời"
              label="Câu trả lời"
              :value.sync="form.body"
              maxlength="5000"
              required
              :state="validateState(`body`)"
              :invalid-feedback="errors.first(`body`)"
              v-validate="{
                required: true,
              }"
              data-vv-as="Câu trả lời"
            />
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button class="btn btn-success" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm câu trả lời
          </b-button>
        </div>
      </form>
    </b-overlay>
    <template #modal-footer>
      <b-button
        :disabled="loading"
        class="btn"
        type="button"
        @click="resetModal"
      >
        Huỷ
      </b-button>
      <template v-if="!isDetail">
        <b-button
          :disabled="loading"
          class="btn btn-inactive ml-3"
          type="button"
          @click="showPopupDelete"
        >
          <b-icon-trash class="mr-2" />
          Xóa câu hỏi
        </b-button>
        <b-button
          :disabled="loading"
          class="btn btn-success ml-3"
          type="button"
          @click="showPopupClose"
        >
          <b-icon-x-square class="mr-2" />
          Đóng câu hỏi
        </b-button>
      </template>
      <b-button
        v-if="isDetail && !isCloseStatus && isWritePermission"
        class="btn btn-success ml-3"
        type="button"
        :disabled="loading"
        @click="handleEdit"
      >
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
        </span>
        Chỉnh sửa
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { MODAL_TYPE } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
import {
  GET_QUESTION,
  DELETE_QUESTION,
  CLOSE_QUESTION,
  DELETE_ANSWER,
  CREATE_ANSWER,
} from '@/core/services/store/question.module';
import { QUESTION_STATUSES } from '../../../../core/plugins/constants';
const { mapActions } = createNamespacedHelpers('question');
export default {
  name: 'ModalDetail',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: MODAL_TYPE.DETAIL,
    },
  },
  data() {
    return {
      loading: false,
      question: {},
      form: {
        body: null,
        professor: null,
      },
      professors: [],
    };
  },
  computed: {
    isDetail() {
      return this.type === MODAL_TYPE.DETAIL;
    },
    isCloseStatus() {
      return this.question?.statusPortal === QUESTION_STATUSES.CLOSE;
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.getQuestion();
  },
  methods: {
    ...mapActions({
      GET_QUESTION,
      DELETE_QUESTION,
      CLOSE_QUESTION,
      DELETE_ANSWER,
      CREATE_ANSWER,
    }),
    resetModal() {
      this.question = {};
      this.form = {
        body: null,
        professor: null,
      };
      this.professors = [];
      this.$emit('reset');
    },
    async getProfessors(showLoading = false) {
      try {
        showLoading && (this.loading = true);
        const { meta, data, error } = await this.$api.get(
          '/Admin/Account/GetAccountForQA',
        );

        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.professors = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        showLoading && (this.loading = false);
      }
    },
    async getQuestion(showLoading = true) {
      showLoading && (this.loading = true);
      const { error, data } = await this.GET_QUESTION(this.id);

      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.question = data;

        if (!this.professors.length && !this.isDetail) {
          await this.getProfessors();
        }
      }

      showLoading && (this.loading = false);
    },
    showPopupDeleteAnswer(answer) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa câu trả lời này không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteAnswer(answer);
          }
        }.bind(this),
      );
    },
    showPopupDelete() {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa câu hỏi này không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteQuestion();
          }
        }.bind(this),
      );
    },
    showPopupClose() {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái câu hỏi này thành đóng không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.closeQuestion();
          }
        }.bind(this),
      );
    },
    async closeQuestion() {
      this.loading = true;
      const { error } = await this.CLOSE_QUESTION(this.id);

      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$emit('reload-data');
        this.resetModal();
      }
      this.loading = false;
    },
    async deleteQuestion() {
      this.loading = true;
      const { error } = await this.DELETE_QUESTION(this.id);

      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$emit('reload-data');
        this.resetModal();
      }
      this.loading = false;
    },
    async deleteAnswer(answer) {
      this.loading = true;
      const { error } = await this.DELETE_ANSWER(answer.id);

      if (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$emit('reload-data');
        await this.getQuestion(false);
      }
      this.loading = false;
    },
    getStatus(status) {
      switch (status) {
        case QUESTION_STATUSES.CLOSE:
          return 'Đóng';
        case QUESTION_STATUSES.WAITING:
          return 'Đã phản hồi';
        case QUESTION_STATUSES.OPEN:
          return 'Chưa phản hồi';

        default:
          return 'Đã phản hồi';
      }
    },
    getClassByStatus(status) {
      switch (status) {
        case QUESTION_STATUSES.CLOSE:
          return 'closed';
        case QUESTION_STATUSES.WAITING:
          return 'waiting';
        case QUESTION_STATUSES.OPEN:
          return 'open';

        default:
          return 'answered';
      }
    },
    handleEdit() {
      this.$emit('change-type', MODAL_TYPE.EDIT);
      this.getProfessors(true);
    },
    formatDate(timestamp) {
      const dateMoment = this.$moment(timestamp);
      if (dateMoment.isValid())
        return this.$moment.unix(timestamp).format('DD/MM/YYYY - HH:mm');

      return null;
    },
    async handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async submit() {
      this.loading = true;
      const params = {
        body: this.form.body,
        questionId: this.id,
        accountId: this.form.professor.id,
        isComment: false,
      };
      const { error } = await this.CREATE_ANSWER(params);

      if (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$emit('reload-data');
        await this.getQuestion(false);
        this.form.body = null;
        this.form.professor = null;
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.question {
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 28px;

  &__name {
    font-weight: bolder;
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }

  &__status {
    width: 84px;
    height: 25px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &.open {
      background: #21a567;
    }

    &.waiting {
      background: #f58220;
    }

    &.closed {
      background: #888c9f;
    }
  }
}

.actions {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.custom-v-dropdown {
  .dropdown-toggle,
  .dropdown-toggle:hover {
    color: inherit;
  }
  .dropdown-menu {
    .b-dropdown-text {
      padding: 0;
      .navi-link {
        padding: 0.5rem 1rem;
        white-space: nowrap;
        font-weight: normal;
        font-size: 13px;

        .menu-icon {
          .svg-icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
